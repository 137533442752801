import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5-5-5-5 to a 5RM`}</p>
    <p>{`Band Pull Aparts 10-10-10-10`}</p>
    <p>{`then,`}</p>
    <p>{`21-S2OH (135/95)`}</p>
    <p>{`21-Ring Rows`}</p>
    <p>{`42-Situps`}</p>
    <p>{`15-S2OH`}</p>
    <p>{`15-Ring Rows`}</p>
    <p>{`30-Situps`}</p>
    <p>{`9-S2OH`}</p>
    <p>{`9-Ring Rows`}</p>
    <p>{`18-Situps`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`Bonus WOD`}{`*`}{` `}</em></p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`25ft Handstand Walk`}</p>
    <p>{`50 Squats`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit Open WOD 18.2 will be announced tonight at 8:00pm at
Games.CrossFit.com.  `}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      